* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Poppins', sans-serif; */
  font-family: "Source Sans Pro", sans-serif;
}

 @font-face {
      font-family: 'Biotif';
      src: url('fonts/Biotif-Regular.eot');
      src: url('fonts/Biotif-Regular.eot?#iefix') format('embedded-opentype'),
          url('fonts/Biotif-Regular.woff2') format('font-woff2'),
          url('fonts/Biotif-Regular.woff') format('font-woff'),
          url('fonts/Biotif-Regular.ttf') format('truetype'),
          url('fonts/Biotif-Regular.svg#Biotif-Regular') format('svg');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
  @font-face {
      font-family: 'Biotif Book';
      src: url('fonts/Biotif-Regular.eot');
      src: url('fonts/Biotif-Regular.eot?#iefix') format('embedded-opentype'),
          url('fonts/Biotif-Regular.woff2') format('font-woff2'),
          url('fonts/Biotif-Regular.woff') format('font-woff'),
          url('fonts/Biotif-Regular.ttf') format('truetype'),
          url('fonts/Biotif-Regular.svg#Biotif-Regular') format('svg');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'Space Mono';
      src: url('fonts/SpaceMono-Regular.eot');
      src: url('fonts/SpaceMono-Regular.eot?#iefix') format('embedded-opentype'),
          url('fonts/SpaceMono-Regular.woff2') format('font-woff2'),
          url('fonts/SpaceMono-Regular.woff') format('font-woff'),
          url('fonts/SpaceMono-Regular.ttf') format('truetype'),
          url('fonts/SpaceMono-Regular.svg#SpaceMono-Regular') format('svg');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Space Mono Bold';
      src: url('fonts/SpaceMono-Bold.eot');
      src: url('fonts/SpaceMono-Bold.eot?#iefix') format('embedded-opentype'),
          url('fonts/SpaceMono-Bold.woff2') format('font-woff2'),
          url('fonts/SpaceMono-Bold.woff') format('font-woff'),
          url('fonts/SpaceMono-Bold.ttf') format('truetype'),
          url('fonts/SpaceMono-Bold.svg#SpaceMono-Bold') format('svg');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    } 

body {
  background-color: #fff;
  /* background-image: url('https://images.pexels.com/photos/189349/pexels-photo-189349.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260');
  background-position: center; /* Center the image */
  /* background-repeat: no-repeat; /* Do not repeat the image */
  /* background-size: cover; */
}
input {
  height: 32px;
  padding: 4px;
  border: none;
  border: 1px solid lightblue;
  border-radius: 4px;
}
.Basic {
  text-indent: 0em;
  border: 1px solid black;
  border-radius: 5px;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

img {
  width: "150px";
  height: "65px";
}

.Signature {
  margin: 25px 0px;
  margin-left: 5%;
}


.Generate {
  margin-top: 15px;
  background-color: black;
}

.main {
  display: flex;
}

.right-side {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 25px;
}

.SocialMediaLinks {
  margin-top: 25px;
}

.part-1 {
  display: inline-block;
  width: 150px;
  min-width: 150px;
}

.part-2 {
  display: inline-block;
  width: 20px;
  min-width: 20px;
}
.part-3 {
  display: inline-block;
  width: 300px;
  min-width: 300px;
}
.logout {
  border-radius: 5px;
  width: 70px;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 20px;
  margin-left: 20px;
}
.back {
  border-radius: 5px;
  width: 70px;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 60px;
  margin-left: 20px;
}
.nav-bar {
  display: flex;
  justify-content: center;
  height: 10vh;
  width: 100%;
  background-color: rgba(74, 121, 74, 0.5);
  text-align: center;
  margin-bottom: 70px;
  padding: 10px;
}
.nav-bar-title {
  flex: 1;
}

.back-btn {
  border-radius: 5px;
  width: 70px;
  height: 30px;
  margin-top: 15px;
  background-color: cadetblue;
  justify-content: center;
}
.logout-btn {
  border-radius: 5px;
  width: 70px;
  height: 30px;
  margin-top: 15px;
  background-color: cadetblue;
  justify-content: center;
}
#logout {
  margin-left: 120px;
}
#back {
  margin-left: 30px;
}

#select-service {
  grid-template-columns: 10fr 2fr;
}

#login {
  display: block;
}
.service-list-form {
  display: flex;
  justify-content: center;
}
.main-template {
  margin-left: 25%;
  width: 40%;
  height: 50vh;
}
#select-service-2 {
  margin-left: 25%;
}
